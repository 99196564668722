
import { defineComponent, onMounted, reactive } from "vue";
import mAxiosApi from "@/api";

export default defineComponent({
  name: "Overview",
  components: {
    //
  },
  props: {
    title: String,
    class: String,
    styleSVG: String,
    bor_n_seq: [String, Number],
  },
  setup(props) {
    const state = reactive({
      drawer: false,
      myPDF: '',
      loaderEnabled : false,
    });


    const affDetail = async () => {
      let myListLiv = {} as any;
      myListLiv = await getAxios("/pdfBordereau/" + props.bor_n_seq);
      state.myPDF = "data:application/pdf;base64," + myListLiv.pdf + "#toolbar=1&navpanes=1&scrollbar=0";

      state.drawer = true;
    };

    onMounted(async () => {
      state.loaderEnabled = false;
    });

    return {
      affDetail,
      props,
      state
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
